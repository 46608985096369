  /*
   *   Rating open modal
   */
  var ratingStarsValue;
  var ratingValue = $("#review").data('rating-value');


  $('#rating-form button[type="submit"]').on('click', function (e) {
      e.preventDefault();

      var model_id = $("input#model_id").val();
      var token = $("input[name='_token']").val();

      var urlPrefix = $('#rating-form').data('url');
      var urlDomain = window.location.origin;

      $.ajax({
          url: urlDomain + "/" + urlPrefix + "/core/models/rated_model",
          type: "POST",
          headers: {
              'X-CSRF-TOKEN': token
          },
          data: {
              model_id,
              token,
              rating: ratingStarsValue,
          },
          success: function (res) {
            //   console.log(res);
              $("#ratingSuccessModal .text-message").text(res.message);

              setTimeout(function () {
                  $('#rating-modal').modal('hide');
                  $("#ratingSuccessModal").modal('show');
              }, 200);

              setTimeout(function () {
                  $("#ratingSuccessModal").modal('hide');

              }, 1500);

              setTimeout(function () {
                  location.reload();
              }, 2000);

          },
          error: function (err) {
              console.log(err);
          },
      });
  });

  $("#review").rating({
      "click": function (e) {
          ratingStarsValue = e.stars;
          $('.rating-value').html('');
          for (var i = 0; i < ratingStarsValue; i++) {
              $('.rating-value').append('<i class="p-star-full"></i>');
          }
      },
      "stars": 5,
      "emptyStar": "far p-star",
      "filledStar": "fas p-star-full c-primary",
      "value": ratingValue
  });

  if (ratingValue) {
      if (ratingValue == Math.floor(ratingValue)) {

          $('#review i').addClass('fas p-star-full');
          // console.log('int');

      } else {
          $('#review i:nth-child(' + Math.round(ratingValue) + ')').toggleClass('fas p-star-full fas p-star');
          // console.log('dec');

      }

      $('#review').on('mouseleave', function () {
          if (ratingValue == Math.floor(ratingValue)) {
              $('#review i').addClass('fas p-star-full');
 
          } else {
              $('#review i:nth-child(' + Math.round(ratingValue) + ')').toggleClass('fas p-star-full fas p-star');
          }
      })
  }

  $('.stars').on('click', function () {
      $('#rating-modal').modal('show');
  })
