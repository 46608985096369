$(".tabela-podaci-h .add-to-cart-btn").on('click', function () {
    let lager = $(".quantity").text();
    let carina = $(".arrivals").text();
    let dodavanje = parseInt($(".unesi-kolicinu").val(), 10);
    let zbir = parseInt(lager) + parseInt(carina);
    if (zbir < dodavanje) {
      $("#vecaKolicina").modal("show");
    } 
  });


  $(".tabela-podaci-h .add-to-cart-btn").click(function () {
    const tableRows = $(".tabela-podaci-h tbody tr");
  
    check = true;
    tableRows.each(function (index, value) {
      const quantityValue = parseInt($(value).find(".quantity").text());
      const arrivalsValue = parseInt($(value).find(".arrivals").text());
      const inputValue = parseInt($(value).find(".unesi-kolicinu").val());
      const zbir = quantityValue + arrivalsValue;
  
      if (inputValue > zbir) {
        console.log(zbir, inputValue);
        $("#vecaKolicina").modal("show");
        check = false;
        return;
      }
    });
  
    $("form").submit(function () {
      if (check == false) {
        return false;
      }
    });
  });
  